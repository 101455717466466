import React from "react"

import SEO from "../components/seo"

import Logo from "../images/popcornready.svg";
import IconMail from "../images/icon-mail.svg";


const IndexPage = () => (
  <div>
    <SEO title="Home" />
    <div className="temp">
      <img className={`c-logo`} src={Logo} alt={`Popcorn Ready`} />
      <span></span>
      <div>
        <p>We craft beautiful digital products, brands and experiences.</p>
        <a href="mailto:info@popcornready.be" title="Get in touch">
            <img src={IconMail} alt={`Popcorn Ready`} />
        </a>
      </div>
    </div>
  </div>
)

export default IndexPage
